// obj chuyển đổi chữ thành số
const objectQuyDoiChu: any = {
  A: 1,
  B: 2,
  C: 3,
  D: 4,
  E: 5,
  F: 6,
  G: 7,
  H: 8,
  I: 9,
  J: 1,
  K: 2,
  L: 3,
  M: 4,
  N: 5,
  O: 6,
  P: 7,
  Q: 8,
  R: 9,
  S: 1,
  T: 2,
  U: 3,
  V: 4,
  W: 5,
  X: 6,
  Y: 7,
  Z: 8,
};

function getDinhCuocDoi(number: number) {
  const dinhCuocDoiMap: any = {
    20: 2,
    30: 3,
    22: 4,
    33: 6,
    1: 10,
  };
  return dinhCuocDoiMap[number] || number;
}

const lamTronSoVeDuoi9GiuSoBacThay: any = (number: number) => {
  // Case 1: number is from 1 to 9, keep it the same
  if (number >= 1 && number <= 9) {
    return number;
  }

  // Case 2: number is one of 10, 11, 20, 22, 30, or 33, keep it the same
  if ([10, 11, 20, 22, 30, 33].includes(number)) {
    return number;
  }

  // Case 3: add the digits together and keep adding if the sum is greater than 11
  let sum = 0;
  while (number > 0) {
    sum += number % 10;
    number = Math.floor(number / 10);
  }

  if (sum <= 11) {
    return sum;
  } else {
    return lamTronSoVeDuoi9GiuSoBacThay(sum);
  }
};

function tinhTongCacSoTuMangGiuSoBacThay(arr: any) {
  let sum = 0;
  for (let index = 0; index < arr.length; index++) {
    // console.log(arr[index]);
    let element = arr[index];
    sum = sum + element;
  }
  let result = lamTronSoVeDuoi9GiuSoBacThay(sum);
  return result;
}

function chuyenDoiDateThanhArr(inputDate: any) {
  const dateArray = inputDate.split("-");
  // typedate yyyy-mm-dd
  let year = dateArray[0];
  let month = dateArray[1];
  let day = dateArray[2];
  // giữ lại số to trong ngày, tháng, năm (nếu tính cả năm 10,20,30 => vẫn cần giữ)
  let dayArray =
    day === "10" ||
    day === "11" ||
    day === "20" ||
    day === "22" ||
    day === "30" ||
    day === "33"
      ? [Number(day)]
      : day.split("").map(Number);
  let monthArray =
    month === "10" ||
    month === "11" ||
    month === "20" ||
    month === "22" ||
    month === "30" ||
    month === "33"
      ? [Number(month)]
      : month.split("").map(Number);
  let yearArr =
    year === "10" ||
    year === "11" ||
    year === "20" ||
    year === "22" ||
    year === "30" ||
    year === "33"
      ? [Number(year)]
      : year.split("").map(Number);
  function xoaSoKhong(arr: any) {
    if (arr.includes(0)) {
      return arr.filter((elem: any) => elem !== 0);
    }
    return arr;
  }
  return {
    day: xoaSoKhong(dayArray),
    month: xoaSoKhong(monthArray),
    year: xoaSoKhong(yearArr),
  };
}

function tinhTongCacSoTuMangKhongGiuSoBacThay(arr: any) {
  let sum = 0;
  for (let index = 0; index < arr.length; index++) {
    // console.log(arr[index]);
    let element = arr[index];
    sum = sum + element;
  }
  let result = lamTronSoVeDuoi9KhongGiuSoBacThay(sum);
  return result;
}

const lamTronSoVeDuoi9KhongGiuSoBacThay: any = (number: any) => {
  if (number <= 9) {
    return number;
  } else {
    let sum = 0;
    String(number)
      .split("")
      .forEach(function (digit) {
        sum = sum + Number(digit);
      });
    return lamTronSoVeDuoi9KhongGiuSoBacThay(sum);
  }
};
function chuyenDoiDateThanhArrKhongGiuSoBacThay(inputDate: any) {
  const dateArray = inputDate.split("-");
  // typedate yyyy-mm-dd
  let year = dateArray[0];
  let month = dateArray[1];
  let day = dateArray[2];
  // giữ lại số to trong ngày, tháng, năm (nếu tính cả năm 10,20,30 => vẫn cần giữ)
  let dayArray = day.split("").map(Number);
  let monthArray = month.split("").map(Number);
  let yearArr = year.split("").map(Number);
  function xoaSoKhong(arr: any) {
    if (arr.includes(0)) {
      return arr.filter((elem: any) => elem !== 0);
    }
    return arr;
  }
  return {
    day: xoaSoKhong(dayArray),
    month: xoaSoKhong(monthArray),
    year: xoaSoKhong(yearArr),
  };
}

function tinhTongGiuSoBacThay(arr: any) {
  let newArr = [];
  for (let index = 0; index < arr.length; index++) {
    // console.log(arr[index]);
    var element = lamTronSoVeDuoi9GiuSoBacThay(arr[index]);
    newArr.push(element);
  }
  return newArr;
}

function chuyenDoiChuThanhSoVaTinhTongArr(arr: any) {
  // Function giúp Cộng các số trong mảng lại với nhau
  function tongSoTuArr(arr: any) {
    let sum = 0;
    for (let i = 0; i < arr.length; i++) {
      sum += arr[i];
    }
    return sum;
  }
  const result = [];
  for (let i = 0; i < arr.length; i++) {
    const char = arr[i].toUpperCase(); // convert to uppercase to match object keys
    const number = objectQuyDoiChu[char];
    if (number) {
      result.push(number);
    }
  }
  const sum = tongSoTuArr(result);
  return sum;
}

function getChuyenDoiSoTenVaHoDem(arr: any) {
  let newArr = [];
  for (let index = 0; index < arr.length; index++) {
    var element = chuyenDoiChuThanhSoVaTinhTongArr(arr[index]);
    newArr.push(element);
  }
  return newArr;
}

function getChuCaiToArr(arr: any) {
  // Convert first name, last name to uppercase, remove diacritics, and put them in an array
  function chuyenDoiTextThanhArr(inputString: any) {
    const unsignedString = inputString
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/Đ/g, "D");
    const processString = unsignedString.replace(/\s+/g, "").toUpperCase();
    const outputString = processString.split("");
    return outputString;
  }
  let newArr = [];
  for (let index = 0; index < arr.length; index++) {
    var element = chuyenDoiTextThanhArr(arr[index]);
    newArr.push(element);
  }
  return newArr;
}

function demSoTrongMang(arr: any) {
  var obj: any = {};
  for (var i = 0; i < arr.length; i++) {
    var element = arr[i];
    if (obj[element]) {
      obj[element]++;
    } else {
      obj[element] = 1;
    }
  }
  // Thêm các khóa chưa xuất hiện trong object và đặt giá trị là 0
  var keys = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 20, 22, 30, 33];
  for (var j = 0; j < keys.length; j++) {
    var key = keys[j];
    if (!obj.hasOwnProperty(key)) {
      obj[key] = 0;
    }
  }
  var sortedArr = Object.entries(obj).sort(function (a: any, b: any) {
    return b[1] - a[1];
  });
  // lấy ra mảng 4 số
  var fourNumber = sortedArr.slice(0, 4);

  return [obj, fourNumber];
}

function getChuyenDoiSoNguyenAmVaPhuAm(arr: any) {
  let newArrNguyenAm = [];
  let newArrPhuAm = [];
  for (let index = 0; index < arr.length; index++) {
    var element = arr[index];
    var nguyenAm = chuyenDoiChuThanhSoVaTinhTongArr(element["nguyenAm"]);
    var phuAm = chuyenDoiChuThanhSoVaTinhTongArr(element["phuAm"]);
    newArrNguyenAm.push(nguyenAm);
    newArrPhuAm.push(phuAm);
  }
  return { nguyenam: newArrNguyenAm, phuam: newArrPhuAm };
}

function chuyenDoiNguyenAmVaPhuAm(arr: any) {
  const vowels = ["A", "E", "I", "O", "U"];
  let is_y_vowel = false;

  if (arr.length === 1 && arr[0] === "Y") {
    is_y_vowel = true;
  } else {
    if (arr[0] === "Y" && !vowels.includes(arr[1])) {
      is_y_vowel = true;
    } else if (
      arr[arr.length - 1] === "Y" &&
      !vowels.includes(arr[arr.length - 2])
    ) {
      is_y_vowel = true;
    } else {
      for (let i = 1; i < arr.length - 1; i++) {
        if (
          arr[i] === "Y" &&
          !vowels.includes(arr[i - 1]) &&
          !vowels.includes(arr[i + 1])
        ) {
          is_y_vowel = true;
          break;
        }
      }
    }
  }

  let regex;
  if (is_y_vowel) {
    regex = /[aeiouy]/i;
  } else {
    regex = /[aeiou]/i;
  }

  const nguyenAm = [];
  const phuAm = [];

  for (let i = 0; i < arr.length; i++) {
    if (regex.test(arr[i])) {
      nguyenAm.push(arr[i]);
    } else {
      phuAm.push(arr[i]);
    }
  }

  return { nguyenAm, phuAm };
}

function getBangSo(obj: any, number: any) {
  var soLuong = obj[number];
  if (soLuong === 0) {
    return "";
  } else {
    return `${number}^${soLuong}`;
  }
}
function chuyenDoiNumberThanhMang(number: any) {
  return String(number).split("").map(Number);
}

export const handleDinhCuocDoi = (data: any) => {
  var today = new Date();
  var year = today.getFullYear();
  let arrNgaySinhKhongBacThay = chuyenDoiDateThanhArrKhongGiuSoBacThay(
    data.birthday
  ).day;
  let arrThangSinhKhongBacThay = chuyenDoiDateThanhArrKhongGiuSoBacThay(
    data.birthday
  ).month;
  let arrNamSinhKhongBacThay = chuyenDoiDateThanhArrKhongGiuSoBacThay(
    data.birthday
  ).year;

  let tongNgaySinhKhongBacThay = tinhTongCacSoTuMangKhongGiuSoBacThay(
    arrNgaySinhKhongBacThay
  );
  let tongThangSinhKhongBacThay = tinhTongCacSoTuMangKhongGiuSoBacThay(
    arrThangSinhKhongBacThay
  );
  let tongNamSinhKhongBacThay = tinhTongCacSoTuMangKhongGiuSoBacThay(
    arrNamSinhKhongBacThay
  );

  let arrNgaySinh = chuyenDoiDateThanhArr(data.birthday).day;
  let arrThangSinh = chuyenDoiDateThanhArr(data.birthday).month;
  let arrNamSinh = chuyenDoiDateThanhArr(data.birthday).year;
  var arrYear = chuyenDoiNumberThanhMang(year);
  let tongNgaySinh = tinhTongCacSoTuMangGiuSoBacThay(arrNgaySinh);
  let tongThangSinh = tinhTongCacSoTuMangGiuSoBacThay(arrThangSinh);
  let tongNamSinh = tinhTongCacSoTuMangGiuSoBacThay(arrNamSinh);

  let arrSoChuDao = [tongNgaySinh, tongThangSinh, tongNamSinh];
  var arrSoNgaySinh = [tongNgaySinh];
  let finalSoChuDao = tinhTongCacSoTuMangGiuSoBacThay(arrSoChuDao);
  var finalSoNgaySinh = tinhTongCacSoTuMangGiuSoBacThay(arrSoNgaySinh);
  var arrNamCaNhan = [...arrYear, ...arrNgaySinh, ...arrThangSinh];
  var finalNamCaNhan = tinhTongCacSoTuMangKhongGiuSoBacThay(arrNamCaNhan);
  var arrHoDem = data.firstName.split(" ");
  var arrTen = data.lastName.split(" ");

  arrTen = getChuCaiToArr(arrTen);
  arrHoDem = getChuCaiToArr(arrHoDem);

  var tongArrTen = getChuyenDoiSoTenVaHoDem(arrTen);
  var tongArrHoDem = getChuyenDoiSoTenVaHoDem(arrHoDem);

  var tongCongArrTen = tinhTongGiuSoBacThay(tongArrTen);
  // console.log(tongCongArrTen);
  var tongCongArrHoDem = tinhTongGiuSoBacThay(tongArrHoDem);
  // console.log(tongCongArrHoDem);

  var tongCongArrVanMenh = [...tongCongArrTen, ...tongCongArrHoDem];
  var finalSoVanMenh = tinhTongCacSoTuMangGiuSoBacThay(tongCongArrVanMenh);

  var arrSoTruongThanh = [finalSoChuDao, finalSoVanMenh];
  var finalSoTruongThanh = tinhTongCacSoTuMangGiuSoBacThay(arrSoTruongThanh);

  var finalSoTen = tinhTongCacSoTuMangGiuSoBacThay(tongCongArrTen);

  var finalSoTenTacY = ""; // mặc định rỗng
  // console.log(typeof tentacy); -> kiểm tra type
  if (data.authorName.trim() === "") {
  } else {
    var arrTenTacY = getChuCaiToArr(data.authorName);
    var tongArrTenTacY = getChuyenDoiSoTenVaHoDem(arrTenTacY);
    // console.log(tongArrTenTacY);
    finalSoTenTacY = tinhTongCacSoTuMangGiuSoBacThay(
      tinhTongGiuSoBacThay(tongArrTenTacY)
    );
    // console.log(finalSoTenTacY);
  }

  let dinh1 = 36 - getDinhCuocDoi(finalSoChuDao);
  let dinh2 = dinh1 + 9;
  let dinh3 = dinh2 + 9;
  let dinh4 = dinh3 + 9;

  let arrTongNgaySinhThangSinh = [
    tongNgaySinhKhongBacThay,
    tongThangSinhKhongBacThay,
  ];
  let arrTongNgaySinhNamSinh = [
    tongNgaySinhKhongBacThay,
    tongNamSinhKhongBacThay,
  ];
  let soDinh1 = tinhTongCacSoTuMangGiuSoBacThay(arrTongNgaySinhThangSinh);
  let soDinh2 = tinhTongCacSoTuMangGiuSoBacThay(arrTongNgaySinhNamSinh);

  let arrTongDinh1Dinh2 = [soDinh1, soDinh2];
  let soDinh3 = tinhTongCacSoTuMangGiuSoBacThay(arrTongDinh1Dinh2);
  let arrTongThangSinhNamSinh = [
    tongThangSinhKhongBacThay,
    tongNamSinhKhongBacThay,
  ];

  function getChiaNguyenAmPhuAm(arr: any) {
    let newArr = [];
    for (let index = 0; index < arr.length; index++) {
      var element = chuyenDoiNguyenAmVaPhuAm(arr[index]);
      newArr.push(element);
    }
    return newArr;
  }

  var arrChiaNguyenAmPhuAmTen = getChiaNguyenAmPhuAm(arrTen);
  var arrChiaNguyenAmPhuAmHoDem = getChiaNguyenAmPhuAm(arrHoDem);
  var tongArrNguyenAmVaPhuAmTen = getChuyenDoiSoNguyenAmVaPhuAm(
    arrChiaNguyenAmPhuAmTen
  );
  var tongArrNguyenAmVaPhuAmHoDem = getChuyenDoiSoNguyenAmVaPhuAm(
    arrChiaNguyenAmPhuAmHoDem
  );
  var tongArrNguyenAmHoVaTen = [
    ...tongArrNguyenAmVaPhuAmTen["nguyenam"],
    ...tongArrNguyenAmVaPhuAmHoDem["nguyenam"],
  ];
  var tongArrPhuAmHoVaTen = [
    ...tongArrNguyenAmVaPhuAmTen["phuam"],
    ...tongArrNguyenAmVaPhuAmHoDem["phuam"],
  ];
  let soDinh4 = tinhTongCacSoTuMangGiuSoBacThay(arrTongThangSinhNamSinh);
  var finalSoNoiTam = tinhTongCacSoTuMangGiuSoBacThay(tongArrNguyenAmHoVaTen);
  var finalSoBieuLo = tinhTongCacSoTuMangGiuSoBacThay(tongArrPhuAmHoVaTen);
  var arrThanSo = [...arrNgaySinh, ...arrThangSinh, ...arrNamSinh];
  var hienThiNgaySinh = arrThanSo.toString();
  // push soDinh values to arrThanSo
  const soDinhValues = [soDinh1, soDinh2, soDinh3, soDinh4];
  // Đinh x2
  arrThanSo.push(...soDinhValues, ...soDinhValues);

  // ten, noi tam, bieulo x1
  // push other values to arrThanSo
  arrThanSo.push(finalSoTen, finalSoNoiTam, finalSoBieuLo);
  // van menh ho ten x3
  // push finalSoVanMenh to arrThanSo
  for (let i = 0; i < 3; i++) {
    arrThanSo.push(finalSoVanMenh);
  }
  // duong doi - chủ đạo x4
  // push finalSoChuDao to arrThanSo
  for (let i = 0; i < 4; i++) {
    arrThanSo.push(finalSoChuDao);
  }
  // truong thanh x3
  // push finalSoTruongThanh to arrThanSo
  for (let i = 0; i < 3; i++) {
    arrThanSo.push(finalSoTruongThanh);
  }
  if (finalSoTenTacY !== "") {
    for (let i = 0; i < 2; i++) {
      arrThanSo.push(finalSoTenTacY);
    }
  }
  var bangSo = demSoTrongMang(arrThanSo);
  var finalSo = bangSo[0];
  var nangLuong = bangSo[1];
  var so1 = getBangSo(finalSo, 1);
  var so2 = getBangSo(finalSo, 2);
  var so3 = getBangSo(finalSo, 3);
  var so4 = getBangSo(finalSo, 4);
  var so5 = getBangSo(finalSo, 5);
  var so6 = getBangSo(finalSo, 6);
  var so7 = getBangSo(finalSo, 7);
  var so8 = getBangSo(finalSo, 8);
  var so9 = getBangSo(finalSo, 9);
  var so10 = getBangSo(finalSo, 10);
  var so11 = getBangSo(finalSo, 11);
  var so20 = getBangSo(finalSo, 20);
  var so22 = getBangSo(finalSo, 22);
  var so30 = getBangSo(finalSo, 30);
  var so33 = getBangSo(finalSo, 33);
  return {
    dinh1,
    dinh2,
    dinh3,
    dinh4,
    soDinh1,
    soDinh2,
    soDinh3,
    soDinh4,
    finalNamCaNhan,
    finalSoChuDao,
    finalSoVanMenh,
    finalSoTruongThanh,
    finalSoTen,
    finalSoBieuLo,
    finalSoNoiTam,
    finalSoTenTacY,
    finalSoNgaySinh,
    nangLuong,
    hienThiNgaySinh,
    so1,
    so2,
    so3,
    so4,
    so5,
    so6,
    so7,
    so8,
    so9,
    so10,
    so11,
    so20,
    so22,
    so30,
    so33,
  };
};
