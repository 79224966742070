import { useEffect } from "react";

export default function PopupNumber(props: {
  data: any;
  setShowPopup: (value: any) => void;
  contentNumber: any;
}) {
  const { data, setShowPopup, contentNumber } = props;
  // const dataNumber: any = jsonData;
  const { number } = data;

  const dataNumber = contentNumber[data.contentType].find(
    (val: any) => val.number === `${number}`
  );
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="bg-[#000000ad] absolute min-h-full w-[100vw] left-0 top-0 z-20 transition-all ease-linear opacity-1 visible duration-700">
      <div className="flex items-center justify-center p-4 sm:p-0">
        <section className="bg-[#FFFFFF] w-10/12 text-[#333333] p-6 relative rounded-lg border border-gray-200 shadow-md mt-4">
          <div className="top-[20px] right-[20px] absolute cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-[18px] h-[18px] relative"
              onClick={() => setShowPopup(undefined)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <p className="text-xl font-semibold">Con số về {data.lable}</p>
          <div className="mt-4 border border-gray-200 p-4">
            <div className="flex text-[17px]">
              <p className="font-bold mr-2 mb-2">Tổng quan về số: </p>
              <p className="text-blue-700 font-bold">{data.number}</p>
            </div>
            <div className="flex text-base mb-2">
              <p className="font-bold mr-2 whitespace-nowrap">Mô Tả: </p>
              <p className="whitespace-pre-wrap">{dataNumber?.mota}</p>
            </div>
            <div className="flex text-base mb-2">
              <p className="font-bold mr-2 whitespace-nowrap">3 Gốc: </p>
              <p className="whitespace-pre-wrap">{dataNumber?.bagoc}</p>
            </div>
            <div className="flex text-base mb-2">
              <p className="font-bold mr-2 whitespace-nowrap">3 Độc: </p>
              <p className="whitespace-pre-wrap">{dataNumber?.badoc}</p>
            </div>
            <div className="flex text-base mb-2">
              <p className="font-bold mr-2 whitespace-nowrap">Cân Bằng: </p>
              <p className="whitespace-pre-wrap">{dataNumber?.canbang}</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
