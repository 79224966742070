interface DefaultSelectOptions {
  value: string;
  label: string;
}

interface SelectControlProps {
  name: string;
  register: any;
  error: any;
  values: DefaultSelectOptions[];
}

export default function SelectControl(props: SelectControlProps) {
  const { name, register, error, values } = props;
  return (
    <div className="mb-4 text-white w-full">
      <select
        className="w-full text-black text-sm md:text-base py-3 border border-gray-300 rounded-3xl focus:outline-none focus:border-indigo-500"
        name={name}
        {...register(name)}
      >
        {values.map((value) => (
          <option value={value.value} key={value.label}>
            {value.label}
          </option>
        ))}
      </select>
      {error && (
        <p className="text-red-400 text-base font-light ml-2">{error}</p>
      )}
    </div>
  );
}
