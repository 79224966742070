const generateDaysArray = () => {
  const daysArray = [];

  for (let i = 1; i <= 31; i++) {
    const day = i.toString().padStart(2, "0");
    const label = `Ngày ${day}`;
    daysArray.push({ value: day, label });
  }
  return daysArray;
};

const generateMonthsArray = () => {
  const monthsArray = [];
  for (let i = 1; i <= 12; i++) {
    const monthValue = i.toString().padStart(2, "0");
    const monthLabel = `Tháng ${monthValue}`;
    monthsArray.push({ value: monthValue, label: monthLabel });
  }
  return monthsArray;
};
const generateGenderArray = () => {
  const gendersArray = [];
  gendersArray.push({ value: "GENDER", label: "Giới tính" });
  gendersArray.push({ value: "MALE", label: "Nam" });
  gendersArray.push({ value: "FEMALE", label: "Nữ" });
  gendersArray.push({ value: "OTHER", label: "Khác" });

  return gendersArray;
};

export { generateDaysArray, generateMonthsArray, generateGenderArray };
