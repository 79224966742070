interface InputControlProps {
  label: string;
  name: string;
  register: any;
  error: any;
  placeholder: string;
}

export default function InputControl(props: InputControlProps) {
  const { label, name, register, error, placeholder } = props;
  return (
    <div className="mb-4 text-white text-lg">
      <label className="block font-bold mb-2 ml-2" htmlFor={name}>
        {!label.includes("*") ? (
          label
        ) : (
          <div>
            <span>{label.slice(0, label.length - 1)}</span>
            <span className="text-red-500">*</span>
          </div>
        )}
      </label>
      <input
        className="w-full text-black px-3 py-3 border border-gray-300 rounded-3xl focus:outline-none focus:border-indigo-500"
        type="text"
        name={name}
        placeholder={placeholder}
        {...register(name)}
      />
      {error && (
        <p className="text-red-400 text-base font-light ml-2">{error}</p>
      )}
    </div>
  );
}
