import InputControl from "app/components/InputControl";
import SelectControl from "app/components/SelectControl";
import {
  generateDaysArray,
  generateGenderArray,
  generateMonthsArray,
} from "app/hooks/useDateMonthGender";

export default function UserForm(props: {
  handleSubmit: any;
  register: any;
  isSubmitting: any;
  handleSubmitData: any;
  errors: any;
  control: any;
}) {
  const { handleSubmit, isSubmitting, register, handleSubmitData, errors } =
    props;

  return (
    <div className="w-full mb-4 md:mr-1 md:mb-0 relative border border-white rounded-lg mt-12">
      <div className="pt-16 h-full text-white">
        <p className="rounded-md absolute left-1/2 -translate-x-1/2 whitespace-nowrap m-0 top-[-24px] md:top-[-28px] bg-black text-xl md:text-3xl font-bold text-center mb-16 px-4 py-2">
          TRA CỨU SỐ HỌC MIỄN PHÍ
        </p>
        <form
          className="w-full md:w-4/5 mx-auto px-4 pb-8 "
          onSubmit={handleSubmit(handleSubmitData)}
        >
          <InputControl
            placeholder="Nhập họ tên"
            label="Họ Tên Khai Sinh*"
            name="name"
            register={register}
            error={errors?.name?.message}
          />
          <InputControl
            placeholder="Nhập tên thường dùng"
            label="Tên Thường Dùng (Nếu có)"
            name="authorName"
            register={register}
            error={errors?.authorName?.message}
          />
          <div className="flex flex-col md:flex-row md:justify-center">
            <div className="w-full md:w-1/2  md:mr-2  text-lg">
              <label className="block font-bold mb-2 ml-2">
                <div>
                  <span>Giới tính</span>
                  <span className="text-red-500">*</span>
                </div>
              </label>
              <SelectControl
                name="gender"
                register={register}
                error={errors?.gender?.message}
                values={generateGenderArray()}
              />
            </div>
            <div className="w-full md:w-1/2 md:ml-2">
              <InputControl
                placeholder="Nhập số điện thoại"
                label="Số điện thoại*"
                name="tel"
                register={register}
                error={errors?.tel?.message}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label className="block font-bold mb-2 ml-2">
              <span>Ngày/Tháng/Năm Sinh</span>
              <span className="text-red-500">*</span>
            </label>
            <div className="flex w-full">
              <div className="w-1/3 mr-1">
                <SelectControl
                  name="day"
                  register={register}
                  error={errors?.day?.message}
                  values={generateDaysArray()}
                />
              </div>
              <div className="w-1/3 mx-0.5">
                <SelectControl
                  name="month"
                  register={register}
                  error={errors?.month?.message}
                  values={generateMonthsArray()}
                />
              </div>
              <div className="w-1/3 ml-1">
                <input
                  className="w-full text-black px-3 py-3 text-sm md:text-base border border-gray-300 rounded-3xl focus:outline-none focus:border-indigo-500"
                  type="number"
                  name="year"
                  placeholder="Năm"
                  {...register("year")}
                />
                {errors?.year?.message && (
                  <p className="text-red-400 text-base font-light ml-2">
                    {errors?.year?.message}
                  </p>
                )}
              </div>
            </div>
          </div>

          <InputControl
            placeholder="Nhập email (Nếu có)"
            label="Email (Nếu có)"
            name="email"
            register={register}
            error={errors?.email?.message}
          />
          <div className="flex justify-center">
            <button
              className="mt-8 px-4 text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-bold text-lg md:text-xl md:px-5 rounded-3xl text-center mr-2 mb-2 py-4"
              type="submit"
              disabled={isSubmitting}
            >
              Tra Cứu Con Số Của Bạn Ngay
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
