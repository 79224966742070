export const saveStateToLocalStorage = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("appState", serializedState);
  } catch (error) {
    console.error("Error saving state to localStorage:", error);
  }
};

export const loadStateFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem("appState");
    return serializedState ? JSON.parse(serializedState) : undefined;
  } catch (error) {
    console.error("Error loading state from localStorage:", error);
    return undefined;
  }
};

export const removeStateFromLocalStorage = () => {
  try {
    localStorage.removeItem("appState");
  } catch (error) {
    console.error("Error removing state from localStorage:", error);
  }
};
