export default function SponsorCard(props: { classRoomLinks: any }) {
  const { classRoomLinks } = props;
  return (
    <div className="max-w-7xl flex flex-wrap justify-center mx-auto w-full pb-24 md:pb-16">
      {classRoomLinks.map((value: any) => (
        <div className="rounded bg-white m-1" key={value}>
          <img
            src={value}
            alt=""
            className="mx-auto w-full h-auto max-w-[96px] max-h-24"
          />
        </div>
      ))}
    </div>
  );
}
