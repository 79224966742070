import { useEffect } from "react";
import { EnergyNumber } from "types/EnergyNumber";

export default function PopupEnergyNumber(props: {
  setShowPopup: (value: any) => void;
  energyNumber: EnergyNumber[];
}) {
  const { setShowPopup, energyNumber } = props;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="bg-[#000000ad] absolute h-full w-[100vw] left-0 top-0 z-20 transition-all ease-linear opacity-1 visible duration-700">
      <div className="flex items-center justify-center p-4 sm:p-0">
        <section className="bg-[#FFFFFF] h-full w-[97%] md:w-11/12 text-[#333333] p-6 relative rounded-lg border border-gray-200 shadow-md mt-4 overflow-auto">
          <div className="top-[20px] right-[20px] absolute cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-[18px] h-[18px] relative"
              onClick={() => setShowPopup(false)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <p className="text-3xl font-bold text-center mb-6">
            Năng Lượng Con Số
          </p>

          <div className="grid mb-8 border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 md:mb-12 md:grid-cols-3">
            {energyNumber &&
              energyNumber.length > 0 &&
              energyNumber.map((val) => (
                <figure className="w-full flex flex-col p-4 bg-white border-b border-gray-200 rounded-t-lg md:rounded-t-none md:rounded-tl-lg md:border-r dark:bg-gray-800 dark:border-gray-700">
                  <blockquote className="mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white text-center mb-4">
                      Năng lượng con số: {val.number}
                    </h3>
                    <div className="flex text-base mb-2">
                      <p className="font-bold mr-2 whitespace-nowrap">
                        Ưu điểm:{" "}
                      </p>
                      <p className="whitespace-pre-wrap break-word">
                        {val?.advantage}
                      </p>
                    </div>
                    <div className="flex text-base mb-2">
                      <p className="font-bold mr-2 whitespace-nowrap">
                        Nhược điểm:{" "}
                      </p>
                      <p className="whitespace-pre-wrap break-word">
                        {val?.weakness}
                      </p>
                    </div>
                  </blockquote>
                </figure>
              ))}
          </div>
        </section>
      </div>
    </div>
  );
}
