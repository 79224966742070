import { yupResolver } from "@hookform/resolvers/yup";
import { CONTENT_DATA } from "app/configs";
import {
  generateDaysArray,
  generateGenderArray,
  generateMonthsArray,
} from "app/hooks/useDateMonthGender";
import {
  getContent,
  getEnergy,
  getTeammate,
  saveUserData,
} from "app/services/userData";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  loadStateFromLocalStorage,
  removeStateFromLocalStorage,
  saveStateToLocalStorage,
} from "utils/localStorage";
import { splitName } from "utils/name";
import Footer from "../Footer";
import Content from "./components/Content";
import IntroductionCard from "./components/IntroductionCard";
import SponsorCard from "./components/SponsorCard";
import UserForm from "./components/UserForm";
import { validate } from "./config/validation";
import "./style/index.css";
import { EnergyNumber } from "types/EnergyNumber";

export default function Home() {
  const [showFunction, setShowFunction] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showImg, setShowImg] = useState<boolean>(false);
  const code = window.location.pathname.split("/");
  const [dataUser, setDataUser] = useState<any>();
  const [content, setContent] = useState<any>();
  const [energyNumbers, setEnergyNumbers] = useState<EnergyNumber[]>([]);

  const [roomLinkAndCode, setRoomLinkAndCode] = useState<any>({});
  const [searchParams] = useSearchParams();
  const tracuu = searchParams.get("tracuu");
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    control,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(validate),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      name: "",
      authorName: "",
      tel: "",
      email: "",
      day: generateDaysArray()[0].value,
      month: generateMonthsArray()[0].value,
      year: "",
      gender: generateGenderArray()[0].value,
    },
  });

  const handleClose = () => {
    setShowFunction(false);
    setDataUser(undefined);
    removeStateFromLocalStorage();
    navigate({
      search: "",
    });
  };

  const handleFetchTeammate = async (userCode: string) => {
    try {
      const data = await getTeammate(userCode);
      if (data?.data && data.data?.groupLink && data.data?.code) {
        setRoomLinkAndCode({
          groupLink: data.data?.groupLink,
          code: data.data?.code,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitData = async (data: any) => {
    const dataName = splitName(data?.name?.trim());
    data.firstName = dataName?.firstName;
    data.lastName = dataName?.lastName;
    const payload = {
      ...data,
    };
    if (roomLinkAndCode?.code) payload.code = roomLinkAndCode?.code;
    payload.birthday = `${data.year}-${data.month}-${data.day}`;
    setDataUser(payload);
    setShowFunction(true);
    saveUserData(payload)
      .then(() => {
        saveStateToLocalStorage(payload);
        navigate({
          search: "tracuu=true",
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };

  const fetchContent = async () => {
    setIsLoading(true);
    try {
      const content = await getContent();
      if (content && content.data) {
        setContent(content.data);
      } else setContent(CONTENT_DATA);
    } catch (error) {
      setContent(CONTENT_DATA);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const fetchEnergy = async () => {
    try {
      const energyNumberData = await getEnergy();
      if (
        energyNumberData &&
        energyNumberData.data &&
        energyNumberData.data.energyNumber
      ) {
        setEnergyNumbers(energyNumberData.data.energyNumber);
      }
    } catch (error) {
      setContent(CONTENT_DATA);
    }
  };

  useEffect(() => {
    handleFetchTeammate(code[1] || "ADMIN");
    fetchEnergy();
    fetchContent();
  }, []);

  useEffect(() => {
    if (tracuu && !isLoading) {
      if (loadStateFromLocalStorage()) {
        setDataUser(loadStateFromLocalStorage());
        setShowFunction(true);
      }
    }
  }, [tracuu, isLoading]);

  return (
    <div className="relative">
      <div
        className={`fixed h-full w-full bg-[#120a1b] transition-opacity z-50 duration-500 ease-in-out flex flex-col justify-center items-center ${
          isLoading ? "opacity-100" : "opacity-0"
        }`}
      >
        <div className="w-40 h-40">
          <img
            src="/images/eye.png"
            alt=""
            className={`w-40 h-40 ${showImg ? "" : "hidden"}`}
            onLoad={() => setShowImg(true)}
          />
        </div>
        {showImg ? (
          <div className="w-2/12 mt-8 bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div className="bg-purple-600 h-2.5 rounded-full dark:bg-purple-500 animate-width"></div>
          </div>
        ) : (
          <div className="mt-8 h-2.5" />
        )}
      </div>
      <div
        className={`relative h-full w-full transition-opacity z-50 duration-500 ease-in-out ${
          isLoading ? "opacity-0" : "opacity-100"
        } overflow-hidden`}
        style={{ backgroundImage: `url("/images/background.jpg")` }}
      >
        {!showFunction ? (
          <div className="max-w-7xl flex flex-wrap justify-center mx-auto p-0.5 md:p-4 relative">
            <UserForm
              handleSubmit={handleSubmit}
              register={register}
              isSubmitting={isSubmitting}
              handleSubmitData={handleSubmitData}
              errors={errors}
              control={control}
            />
            <IntroductionCard contentTopPage={content?.contentTopPage} />
            <div className="w-full mb-4 md:mr-1 md:mb-0 relative border border-white rounded-lg mt-12">
              <div className="pt-16 h-full text-white px-4 pb-8">
                <p className="rounded-md absolute left-1/2 -translate-x-1/2 whitespace-nowrap m-0 top-[-24px] md:top-[-28px] bg-black text-xl md:text-3xl font-bold text-center mb-16 px-4 py-2">
                  CHƯƠNG TRÌNH ĐANG DIỄN RA
                </p>
                <span className="w-full md:h-[600px] flex flex-col items-center rounded-lg shadow md:flex-row">
                  <div className="md:w-[35%] flex flex-col justify-between md:pr-2 pb-2 md:pb-0 leading-normal">
                    <img
                      src="images/ve-moi.jpg"
                      alt="Vé mời"
                      className="h-[600px] w-auto"
                    />
                  </div>
                  <div className="w-full md:w-[65%] h-full relative">
                    <iframe
                      title="Chuong trinh"
                      src="https://drive.google.com/file/d/1GMW77p92jpnyberzrUtfbJ-ojXR8-u7Z/preview"
                      allow="autoplay"
                      className="md:h-full h-[400px] w-full"
                      allowFullScreen
                    ></iframe>
                    <div className="w-[80px] h-[80px] absolute opacity-0 right-0 top-0" />
                  </div>
                </span>
                <div className="w-full flex justify-center items-center mt-4">
                  <a href="https://venhahanhphuc.com/?utm_source=nVPDN">
                    <button
                      type="button"
                      className="inline-flex items-center mt-6 px-4 text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-bold text-lg md:text-xl md:px-5 rounded-3xl text-center mr-2 mb-2 py-4"
                    >
                      ĐĂNG KÝ NGAY
                      <svg
                        className="w-3.5 h-3.5 ml-2"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M1 5h12m0 0L9 1m4 4L9 9"
                        />
                      </svg>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          dataUser && (
            <div className="max-w-7xl flex flex-wrap justify-center mx-auto p-4 ">
              <Content
                energyNumbers={energyNumbers}
                contentNumber={content}
                birthday={dataUser?.birthday}
                firstName={dataUser?.firstName}
                name={dataUser?.name}
                authorName={dataUser?.authorName}
                lastName={dataUser?.lastName}
                handleClose={handleClose}
                roomLink={roomLinkAndCode?.groupLink}
              />
            </div>
          )
        )}
        {content?.classRoomLinks && content?.classRoomLinks?.length > 0 ? (
          <SponsorCard classRoomLinks={content?.classRoomLinks} />
        ) : (
          <div className="pb-24 md:pb-16" />
        )}
        <Footer />
      </div>
    </div>
  );
}
