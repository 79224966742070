export const splitName = (name: string) => {
  // Tìm vị trí của khoảng trắng cuối cùng trong chuỗi name
  const lastSpaceIndex = name.lastIndexOf(" ");

  if (lastSpaceIndex === -1) {
    // Nếu không tìm thấy khoảng trắng, name chỉ có một từ
    return {
      firstName: name,
      lastName: name,
    };
  } else {
    // Cắt chuỗi firstName từ vị trí đầu đến khoảng trắng cuối cùng
    const firstName = name.slice(0, lastSpaceIndex);
    // Cắt chuỗi lastName từ khoảng trắng cuối cùng đến hết chuỗi
    const lastName = name.slice(lastSpaceIndex + 1);

    return {
      firstName: firstName,
      lastName: lastName,
    };
  }
};
