import apiClient from "./api";

export const saveUserData = async (payload: any) => {
  const response: any = await apiClient.post(`/userData/user-data`, payload);
  return response;
};

export const getContent = async () => {
  const response: any = await apiClient.get(`/content/content`);
  return response;
};

export const getTeammate = async (code: string) => {
  const response: any = await apiClient.get(`user/classRoom/${code}`);
  return response;
};

export const getEnergy = async () => {
  const response: any = await apiClient.get(`/energy-number/energy-number`);
  return response;
};
