import { useState } from "react";
import { handleDinhCuocDoi } from "utils/helper";
import PopupNumber from "./PopupNumber";
import PopupEnergyNumber from "./PopupEnergyNumber";
import { EnergyNumber } from "types/EnergyNumber";

export default function Content(props: {
  birthday: string;
  firstName: string;
  lastName: string;
  authorName: string;
  name: string;
  contentNumber: any;
  handleClose: () => void;
  roomLink: string;
  energyNumbers: EnergyNumber[];
}) {
  const {
    birthday,
    firstName,
    lastName,
    authorName,
    name,
    contentNumber,
    roomLink,
    handleClose,
    energyNumbers,
  } = props;

  const data = handleDinhCuocDoi({ birthday, firstName, lastName, authorName });
  const [showPopup, setShowPopup] = useState<any>();
  const [showEnergyNumber, setShowEnergyNumber] = useState<boolean>(false);

  const arrChiTiet = [
    {
      lable: "Tên",
      contentType: "contentNumber",
      number: data.finalSoTen,
    },
    {
      lable: "Nội Tâm",
      contentType: "contentInner",
      number: data.finalSoNoiTam,
    },
    {
      lable: "Tương Tác",
      contentType: "contentInteract",
      number: data.finalSoBieuLo,
    },
    {
      lable: "Sứ Mệnh",
      contentType: "contentMission",
      number: data.finalSoVanMenh,
    },
    {
      lable: "Trưởng Thành",
      contentType: "contentMature",
      number: data.finalSoTruongThanh,
    },
    {
      lable: "Đường Đời",
      contentType: "contentLife",
      number: data.finalSoChuDao,
    },
    {
      lable: "Tên Tác Ý",
      contentType: "contentNumber",
      number: data.finalSoTenTacY,
    },
  ];
  // const dataNumber: any = jsonData;
  const dataNumber = contentNumber["contentLife"].find(
    (val: any) => val.number === `${data.finalSoChuDao}`
  );

  return (
    <div className="bg-white w-full">
      <div className="flex flex-wrap">
        <div
          id="soChuDao"
          className="w-full md:w-[calc(30%-8px)] flex justify-center p-2"
        >
          <div className="flex flex-col items-center border border-gray-200 rounded-lg shadow-sm p-2 h-full w-full">
            <p className="text-2xl font-bold mb-12 mt-2">Số Chủ Đạo</p>
            <div className="flex items-center justify-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-semibold text-white w-52 h-52 rounded-full">
              <p className="text-[100px]">{data.finalSoChuDao}</p>
            </div>
            <p className="mt-4 text-[28px] font-semibold text-blue-800 uppercase">{`${name}`}</p>
            {authorName && (
              <p className="text-base font-medium text-blue-800 uppercase">
                Tên Phụ: {authorName}
              </p>
            )}
            <p className="text-base font-medium text-blue-800">{birthday}</p>
            <span className="text-base font-normal text-blue-500 mb-6">
              Năm cá nhân:{" "}
              <span className="text-base font-medium text-blue-800">
                {data.finalNamCaNhan}
              </span>
            </span>
            <button
              type="button"
              onClick={handleClose}
              className="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            >
              Quay lại
            </button>
          </div>
        </div>
        <div
          id="bonDinhCuocDoi"
          className="w-full md:w-[calc(70%-8px)] overflow-y-auto md:overflow-hidden p-2"
        >
          <div className="border border-gray-200 rounded-lg shadow-sm p-2 h-full flex flex-col items-center">
            <p className="text-2xl font-bold mb-2">Bốn đỉnh cuộc đời</p>

            <div className="relative overflow-x-auto w-full border border-gray-200">
              <table className="table-auto text-center w-full">
                <tbody>
                  <tr>
                    <th className="px-4 py-3 border">
                      <b>
                        (**)
                        <br />4 Đỉnh
                      </b>
                    </th>
                    <th className="px-4 py-3 border text-center">
                      <div className="flex items-center mx-auto text-2xl justify-center bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 text-white rounded-full w-[60px] h-[60px]">
                        {data.soDinh1}
                      </div>
                    </th>
                    <th className="px-4 py-3 border text-center">
                      <div className="flex items-center mx-auto text-2xl justify-center bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 text-white rounded-full w-[60px] h-[60px]">
                        {data.soDinh2}
                      </div>
                    </th>
                    <th className="px-4 py-3 border text-center">
                      <div className="flex items-center mx-auto text-2xl justify-center bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 text-white rounded-full w-[60px] h-[60px]">
                        {data.soDinh3}
                      </div>
                    </th>
                    <th className="px-4 py-3 border text-center">
                      <div className="flex items-center mx-auto text-2xl justify-center bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 text-white rounded-full w-[60px] h-[60px]">
                        {data.soDinh4}
                      </div>
                    </th>
                    <th rowSpan={2} className="px-4 py-3 border">
                      <b>
                        Bạn có
                        <br />
                        Tròn vai
                        <br />3 Gốc?
                      </b>
                    </th>
                  </tr>
                  <tr>
                    <td className="border px-4 py-3 text-base font-bold">
                      Tuổi
                    </td>
                    <td className="border px-4 py-3">
                      <div className="flex flex-col items-center text-lg font-bold">
                        <img
                          src="/images/arrow.svg"
                          alt=""
                          className="w-12 h-8 transform -rotate-90"
                        />
                        <span>{data.dinh1}</span>
                      </div>
                    </td>
                    <td className="border px-4 py-3">
                      <div className="flex flex-col items-center text-lg font-bold">
                        <img
                          src="/images/arrow.svg"
                          alt=""
                          className="w-12 h-8 transform -rotate-90"
                        />
                        <span>{data.dinh2}</span>
                      </div>
                    </td>
                    <td className="border px-4 py-3">
                      <div className="flex flex-col items-center text-lg font-bold">
                        <img
                          src="/images/arrow.svg"
                          alt=""
                          className="w-12 h-8 transform -rotate-90"
                        />
                        <span>{data.dinh3}</span>
                      </div>
                    </td>
                    <td className="border px-4 py-3">
                      <div className="flex flex-col items-center text-lg font-bold">
                        <img
                          src="/images/arrow.svg"
                          alt=""
                          className="w-12 h-8 transform -rotate-90"
                        />
                        <span>{data.dinh4}</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="border px-4 py-3 text-2xl font-bold"
                      rowSpan={5}
                    >
                      Thời
                      <br />
                      Niên
                      <br />
                      Thiếu
                    </td>
                    <td className="border px-4 py-3" colSpan={4}>
                      <div className="flex w-full flex-row items-center justify-end text-[17px] font-bold">
                        <div className="flex justify-center items-center whitespace-nowrap">
                          <span>(****) Số chủ đạo, đường đời:</span>
                          <div className="flex items-center ml-4 text-xl justify-center bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 text-white rounded-full w-12 h-12">
                            {data.finalSoChuDao}
                          </div>
                        </div>
                        <img
                          src="/images/arrow.svg"
                          alt=""
                          className="w-12 h-12 pl-4"
                        />
                      </div>
                    </td>
                    <td
                      className="border px-4 py-3 text-2xl font-bold"
                      rowSpan={5}
                    >
                      Khi
                      <br />
                      Viên
                      <br />
                      Mãn
                    </td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-3" colSpan={4}>
                      <div className="flex w-full flex-row items-center justify-end text-[17px] font-bold">
                        <div className="flex justify-center items-center whitespace-nowrap">
                          <span> (***) Số họ tên, vận mệnh:</span>
                          <div className="flex items-center ml-4 text-xl justify-center bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 text-white rounded-full w-12 h-12">
                            {data.finalSoVanMenh}
                          </div>
                        </div>
                        <img
                          src="/images/arrow.svg"
                          alt=""
                          className="w-12 h-12 pl-4"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-3" colSpan={4}>
                      <div className="flex w-full flex-row items-center justify-end text-[17px] font-bold">
                        <div className="flex justify-center items-center whitespace-nowrap">
                          <span>(***) Trưởng thành:</span>
                          <div className="flex items-center ml-4 text-xl justify-center bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 text-white rounded-full w-12 h-12">
                            {data.finalSoTruongThanh}
                          </div>
                        </div>
                        <img
                          src="/images/arrow.svg"
                          alt=""
                          className="w-12 h-12 pl-4"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-3" colSpan={4}>
                      <div className="flex w-full flex-row items-center justify-end text-[17px] font-bold">
                        <div className="flex justify-center items-center whitespace-nowrap">
                          <span>(*) Tên: </span>
                          <div className="flex items-center mx-2 text-xl justify-center bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 text-white rounded-full w-12 h-12">
                            {data.finalSoTen}
                          </div>
                        </div>
                        <div className="flex justify-center items-center whitespace-nowrap">
                          <span>(**) Tên Tác ý: </span>
                          <div className="flex items-center ml-4 text-xl justify-center bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 text-white rounded-full w-12 h-12">
                            {data.finalSoTenTacY}
                          </div>
                        </div>
                        <img
                          src="/images/arrow.svg"
                          alt=""
                          className="w-12 h-12 pl-4"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-3" colSpan={4}>
                      <div className="flex w-full flex-row items-center justify-end text-[15px] font-medium italic">
                        <span>
                          (*) Biểu lộ: {data.finalSoBieuLo}, Nội tâm:{" "}
                          {data.finalSoNoiTam}, Ngày sinh:{" "}
                          {data.hienThiNgaySinh}
                        </span>
                        <img
                          src="/images/arrow.svg"
                          alt=""
                          className="w-12 h-12 pl-4"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          id="nangLuongConSo"
          className="w-full md:w-[calc(30%-8px)] overflow-y-auto md:overflow-hidden p-2"
        >
          <div className="border border-gray-200 rounded-lg shadow-sm p-2 h-full">
            <div className="flex flex-row justify-center items-center">
              <p className="text-2xl font-bold">Năng lượng con số </p>
              <button
                type="button"
                onClick={() => setShowEnergyNumber(true)}
                className="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-3 py-2.5 text-center ml-2"
              >
                Xem chi tiết
              </button>
            </div>
            <div className="relative overflow-x-auto w-full border border-gray-200 mt-4">
              <table className="table-auto text-center w-full">
                <tbody>
                  <tr>
                    <th
                      className="px-4 py-3 border border-gray-400 bg-[#d2fadd]"
                      colSpan={3}
                    >
                      Số cơ bản, cần tròn vai
                    </th>
                    <th
                      className="px-4 py-3 border border-gray-400 bg-[#ffe3e3]"
                      colSpan={2}
                    >
                      Số bậc thầy
                    </th>
                  </tr>
                  <tr>
                    <th className="px-4 py-3 border border-gray-400  bg-[#d2fadd]">
                      {data.so3}
                    </th>
                    <th className="px-4 py-3 border border-gray-400  bg-[#d2fadd]">
                      {data.so6}
                    </th>
                    <th className="px-4 py-3 border border-gray-400  bg-[#d2fadd]">
                      {data.so9}
                    </th>
                    <th className="px-4 py-3 border border-gray-400 bg-[#ffe3e3]">
                      {data.so30}
                    </th>
                    <th className="px-4 py-3 border border-gray-400 bg-[#ffe3e3]">
                      {data.so33}
                    </th>
                  </tr>
                  <tr>
                    <th className="px-4 py-3 border border-gray-400  bg-[#d2fadd]">
                      {data.so2}
                    </th>
                    <th className="px-4 py-3 border border-gray-400  bg-[#d2fadd]">
                      {data.so5}
                    </th>
                    <th className="px-4 py-3 border border-gray-400  bg-[#d2fadd]">
                      {data.so8}
                    </th>
                    <th className="px-4 py-3 border border-gray-400 bg-[#ffe3e3]">
                      {data.so20}
                    </th>
                    <th className="px-4 py-3 border border-gray-400 bg-[#ffe3e3]">
                      {data.so11}
                    </th>
                  </tr>
                  <tr>
                    <th className="px-4 py-3 border border-gray-400  bg-[#d2fadd]">
                      {data.so1}
                    </th>
                    <th className="px-4 py-3 border border-gray-400  bg-[#d2fadd]">
                      {data.so4}
                    </th>
                    <th className="px-4 py-3 border border-gray-400  bg-[#d2fadd]">
                      {data.so7}
                    </th>
                    <th className="px-4 py-3 border border-gray-400 bg-[#ffe3e3]">
                      {data.so10}
                    </th>
                    <th className="px-4 py-3 border border-gray-400 bg-[#ffe3e3]">
                      {data.so22}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mt-2">
              {data.nangLuong.map((val: any, index: number) => (
                <div className="flex items-center">
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0,0,256,256"
                    className="pr-2"
                  >
                    <g fill="#21bbf3">
                      <g transform="scale(5.33333,5.33333)">
                        <path d="M24,8c8.822,0 16,7.178 16,16c0,8.822 -7.178,16 -16,16c-8.822,0 -16,-7.178 -16,-16c0,-8.822 7.178,-16 16,-16M24,4c-11.046,0 -20,8.955 -20,20c0,11.044 8.954,20 20,20c11.046,0 20,-8.956 20,-20c0,-11.045 -8.954,-20 -20,-20z"></path>
                        <path d="M35,24l-10,-8v16z"></path>
                        <path d="M13,22h15v4h-15z"></path>
                      </g>
                    </g>
                  </svg>
                  Năng lượng cao thứ {index + 1}:
                  <span className="font-bold pl-1">
                    {val[0]}^{val[1]}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div id="chiTietBoSo" className="w-full md:w-[calc(70%-8px)] p-2">
          <div className="border border-gray-200 rounded-lg shadow-sm p-2 h-full">
            <p className="text-2xl font-bold text-center mb-4">
              Chi tiết bộ số (Nhấn từng số để xem)
            </p>
            <div className="p-4">
              {arrChiTiet.map((val) => (
                <button
                  onClick={() => setShowPopup(val)}
                  className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800"
                >
                  <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                    {val.lable}: {val.number}
                  </span>
                </button>
              ))}
            </div>
            <div className="border border-gray-500 p-4 rounded-lg m-4">
              <div className="border-b pb-6 mb-4">
                <div className="">
                  <div className="flex text-[17px]">
                    <p className="font-bold mr-2 mb-4">Tổng quan về số: </p>
                    <p className="text-blue-700 font-bold">
                      {data.finalSoChuDao}
                    </p>
                  </div>
                  <div className="flex text-base mb-2">
                    <p className="font-bold mr-2 whitespace-nowrap">Mô Tả: </p>
                    <p className="whitespace-pre-wrap">{dataNumber?.mota}</p>
                  </div>
                  <div className="flex text-base mb-2">
                    <p className="font-bold mr-2 whitespace-nowrap">3 Gốc: </p>
                    <p className="whitespace-pre-wrap">{dataNumber?.bagoc}</p>
                  </div>
                  <div className="flex text-base mb-2">
                    <p className="font-bold mr-2 whitespace-nowrap">3 Độc: </p>
                    <p className="whitespace-pre-wrap">{dataNumber?.badoc}</p>
                  </div>
                  <div className="flex text-base mb-2">
                    <p className="font-bold mr-2 whitespace-nowrap">
                      Cân Bằng:{" "}
                    </p>
                    <p className="whitespace-pre-wrap">{dataNumber?.canbang}</p>
                  </div>
                </div>
              </div>
              <a
                href={roomLink}
                target="t_blank"
                className="mt-4 uppercase font-bold text-blue-600"
              >
                Tham gia group để được hiểu các chỉ số của bạn chuyên sâu hơn
              </a>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <PopupNumber
          data={showPopup}
          setShowPopup={setShowPopup}
          contentNumber={contentNumber}
        />
      )}
      {showEnergyNumber && (
        <PopupEnergyNumber
          setShowPopup={setShowEnergyNumber}
          energyNumber={energyNumbers}
        />
      )}
    </div>
  );
}
