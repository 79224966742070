export default function IntroductionCard(props: { contentTopPage: any }) {
  const { contentTopPage } = props;
  return (
    <div className="w-full bg-white rounded-lg shadow-2xl shadow-purple-600 mt-4">
      <div className="md:w-full md:h-full md:my-0">
        <div className="flex flex-col bg-white  rounded-lg shadow md:flex-row h-full">
          <img
            className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-80 md:rounded-none md:rounded-l-lg"
            src={contentTopPage?.imgUrl}
            alt=""
          />
          <div className="flex flex-col p-4 leading-normal whitespace-break-spaces">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white text-center">
              {contentTopPage?.title}
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
              {contentTopPage?.content}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
