import * as yup from "yup";
const PHONE_NUMBER_REGEX =
  /^(8403|8405|8407|8409|8408)\d{8}$|^(843|845|847|840|848)\d{8}$|^0[35789]\d{8}$|^02\d{9}$/;

export const validate = yup.object().shape({
  name: yup.string().required("Hãy nhập họ và tên của bạn"),
  authorName: yup.string().optional(),
  day: yup.string().required("Hãy chọn ngày sinh của bạn"),
  month: yup.string().required("Hãy chọn tháng sinh của bạn"),
  year: yup.string().required("Hãy nhập năm sinh của bạn"),
  tel: yup
    .string()
    .required("Hãy nhập số điện thoại của bạn")
    .matches(PHONE_NUMBER_REGEX, "Hãy nhập đúng định dạng số điện thoại"),
  email: yup.string().optional(),
  gender: yup
    .string()
    .required("Hãy chọn giới tính của bạn")
    .oneOf(["MALE", "FEMALE", "OTHER"], "Hãy chọn giới tính của bạn"),
});
