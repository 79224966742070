import { API_HOST } from "app/configs/app";
import axios, { AxiosResponse } from "axios";

const apiClient = axios.create({
  baseURL: API_HOST,
  headers: {},
});

function onResponse(response: AxiosResponse): AxiosResponse {
  return response.data;
}
async function onRequest(config: any) {
  try {
    // eslint-disable-next-line
  } catch (e) {}

  return config;
}
apiClient.interceptors.request.use(onRequest);
apiClient.interceptors.response.use(onResponse);

export default apiClient;
