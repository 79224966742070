export const CONTENT_DATA = {
  contentNumber: [
    {
      number: "0",
      dketbagoc: "Bậc thầy đạo lý Sẻ chia cống hiến",
      dketbadoc: "Hoang mang Thu mình lại",
      mota: "",
      bagoc: "Đạo lý, buông xả, tánh không, vô ngã",
      badoc: "Hoang mang vô định",
    },
    {
      number: "1",
      dketbagoc: "Độc lập Bản lĩnh",
      dketbadoc: "Ích kỷ Cái tôi lớn",
      mota: "Cá Tính Mạnh - Thành Và Bại",
      bagoc:
        "Tinh thần thủ lĩnh, quyết đoán, thực tế, (lãnh đạo, chuyên gia, kiên định, đôi lúc không cần ai hiểu)",
      badoc: "Tham vọng, độc tài bảo thủ, cái tôi lớn.",
    },
    {
      number: "2",
      dketbagoc: "An nhiên Vị tha",
      dketbadoc: "Thất thường\r\nLệ thuộc",
      mota: "Quan Sát Hay Phản Ứng? An Nhiên Hay Phụ Thuộc?",
      bagoc: "Trực giác, cảm xúc, nhạy cảm, tâm hồn vị tha",
      badoc:
        "Vui giận thất thường, dính mắc, lệ thuộc. Làm việc tùy hứng, không thích bị áp lực, gà công nghiệp.",
    },
    {
      number: "3",
      dketbagoc: "Học sâu Tài năng",
      dketbadoc: "Học cạn Khoe mẽ",
      mota: "Học Sâu Hay Học Cạn?",
      bagoc:
        "Tư duy, học hỏi & tìm hiểu theo chiều sâu, có thể hài hước & phán xét (tư duy mở, hài hòa, khiếu truyền cảm hứng)",
      badoc: "Tư duy hời hợt, cạn cợt, lười học hỏi",
    },
    {
      number: "4",
      dketbagoc: "Thực tế Sắp xếp",
      dketbadoc: "Thực dụng Ngoan cố",
      mota: "Kaizen Hay Thực Dụng?",
      bagoc:
        "Hành động, thực tế, óc tổ chức, sắp xếp, chỉn chu (chuyên gia). Khi đã trưởng thành: gặt hái thành quả do cố gắng những năm trước đó, 5S, KAIZEN.",
      badoc: "Thực dụng, có thể bị vật chất hóa, cứng nhắc, cầu toàn thái quá",
    },
    {
      number: "5",
      dketbagoc: "Kết nối từ tâm Tự do, lan tỏa",
      dketbadoc: "Kết nối toan tính Tự do quá đà",
      mota: "Trung Tâm Kết Nối Từ Tâm",
      bagoc:
        "Trung tâm KẾT NỐI từ TÂM: Tự do thể hiện/khiếu nghệ thuật /Chia sẻ thiện nguyện..",
      badoc:
        "Dễ bị ngoại cảnh ảnh hưởng, Thất thường, hoang dã, dễ sa đà, nhạy cảm quá sinh sợ hãi.",
    },
    {
      number: "6",
      dketbagoc: "Sáng tạo Yêu thương",
      dketbadoc: "Tối tạo Dính mắc",
      mota: "Yêu Thương Hay Dính Mắc? Sáng Tạo Hay Tối Tạo?",
      bagoc: "Sáng tạo, yêu thương (diện hẹp)",
      badoc:
        "Cẩn trọng, TÂM TƯỞNG thái quá, lan man, bất an, Stress, dính mắc.",
    },
    {
      number: "7",
      dketbagoc: "Sâu sắc, triết lý Vượt khó khổ",
      dketbadoc: "Lý thuyết suông Chìm đắm",
      mota: "Trưởng Thành Từ Khổ Đế",
      bagoc:
        "Nghị lực, trưởng thành qua khó khăn, nỗi đau, KHỔ ĐẾ: Triết lý, sâu sắc, đạo lý => chia sẻ, giảng dạy",
      badoc:
        "Suy tư thiếu thực tế, lý thuyết suông, cứng đầu, đắm chìm hoặc né tránh khổ đế.",
    },
    {
      number: "8",
      dketbagoc: "Uy quyền Chuyên gia về tâm",
      dketbadoc: "Kiểm soát Chuyên gia tiền",
      mota: "Tâm Linh Hay Tiền Bạc?",
      bagoc:
        "Độc lập, đáng tin cậy, ít bày tỏ tình cảm => Leader, chuyên gia => Quản lý kinh doanh, dẫn dắt tinh thần.",
      badoc: "Bất hợp tác, cô độc, lạnh nhạt, dễ bị tham tiền, thao túng.",
    },
    {
      number: "9",
      dketbagoc: "Cộng đồng Lý tưởng",
      dketbadoc: "Nhụt chí Ảo tưởng",
      mota: "Lý Tưởng Hay Ảo Tưởng? Hoài Bão Hay Tham Vọng?",
      bagoc:
        "Hoài bão, trách nhiệm, lý tưởng => hướng về con người, cộng đồng (có hiểu biết).",
      badoc: "Tham vọng, ảo tưởng, nhụt chí, chán đời.",
    },
    {
      number: "10",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 1",
      badoc: "Mắc kẹt ở số 1, không hoàn thành bài học (số 0)",
    },
    {
      number: "11",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 1 và 2",
      badoc: "Mắc kẹt ở số 1 và 2, không hoàn thành bài học (số 0).",
    },
    {
      number: "20",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 2",
      badoc: "Mắc kẹt ở số 2, không hoàn thành bài học (số 0)",
    },
    {
      number: "22",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 2 và 4",
      badoc: "Mắc kẹt ở số 2 và 4, không hoàn thành bài học (số 0)",
    },
    {
      number: "30",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 3",
      badoc: "Mắc kẹt ở số 3, không hoàn thành bài học (số 0).",
    },
    {
      number: "33",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 3 và 6",
      badoc: "Mắc kẹt ở số 3 và 6, không hoàn thành bài học (số 0)",
    },
  ],
  contentInner: [
    {
      number: "0",
      dketbagoc: "Bậc thầy đạo lý Sẻ chia cống hiến",
      dketbadoc: "Hoang mang Thu mình lại",
      mota: "",
      bagoc: "Đạo lý, buông xả, tánh không, vô ngã",
      badoc: "Hoang mang vô định",
    },
    {
      number: "1",
      dketbagoc: "Độc lập Bản lĩnh",
      dketbadoc: "Ích kỷ Cái tôi lớn",
      mota: "Cá Tính Mạnh - Thành Và Bại",
      bagoc:
        "Tinh thần thủ lĩnh, quyết đoán, thực tế, (lãnh đạo, chuyên gia, kiên định, đôi lúc không cần ai hiểu)",
      badoc: "Tham vọng, độc tài bảo thủ, cái tôi lớn.",
    },
    {
      number: "2",
      dketbagoc: "An nhiên Vị tha",
      dketbadoc: "Thất thường\r\nLệ thuộc",
      mota: "Quan Sát Hay Phản Ứng? An Nhiên Hay Phụ Thuộc?",
      bagoc: "Trực giác, cảm xúc, nhạy cảm, tâm hồn vị tha",
      badoc:
        "Vui giận thất thường, dính mắc, lệ thuộc. Làm việc tùy hứng, không thích bị áp lực, gà công nghiệp.",
    },
    {
      number: "3",
      dketbagoc: "Học sâu Tài năng",
      dketbadoc: "Học cạn Khoe mẽ",
      mota: "Học Sâu Hay Học Cạn?",
      bagoc:
        "Tư duy, học hỏi & tìm hiểu theo chiều sâu, có thể hài hước & phán xét (tư duy mở, hài hòa, khiếu truyền cảm hứng)",
      badoc: "Tư duy hời hợt, cạn cợt, lười học hỏi",
    },
    {
      number: "4",
      dketbagoc: "Thực tế Sắp xếp",
      dketbadoc: "Thực dụng Ngoan cố",
      mota: "Kaizen Hay Thực Dụng?",
      bagoc:
        "Hành động, thực tế, óc tổ chức, sắp xếp, chỉn chu (chuyên gia). Khi đã trưởng thành: gặt hái thành quả do cố gắng những năm trước đó, 5S, KAIZEN.",
      badoc: "Thực dụng, có thể bị vật chất hóa, cứng nhắc, cầu toàn thái quá",
    },
    {
      number: "5",
      dketbagoc: "Kết nối từ tâm Tự do, lan tỏa",
      dketbadoc: "Kết nối toan tính Tự do quá đà",
      mota: "Trung Tâm Kết Nối Từ Tâm",
      bagoc:
        "Trung tâm KẾT NỐI từ TÂM: Tự do thể hiện/khiếu nghệ thuật /Chia sẻ thiện nguyện..",
      badoc:
        "Dễ bị ngoại cảnh ảnh hưởng, Thất thường, hoang dã, dễ sa đà, nhạy cảm quá sinh sợ hãi.",
    },
    {
      number: "6",
      dketbagoc: "Sáng tạo Yêu thương",
      dketbadoc: "Tối tạo Dính mắc",
      mota: "Yêu Thương Hay Dính Mắc? Sáng Tạo Hay Tối Tạo?",
      bagoc: "Sáng tạo, yêu thương (diện hẹp)",
      badoc:
        "Cẩn trọng, TÂM TƯỞNG thái quá, lan man, bất an, Stress, dính mắc.",
    },
    {
      number: "7",
      dketbagoc: "Sâu sắc, triết lý Vượt khó khổ",
      dketbadoc: "Lý thuyết suông Chìm đắm",
      mota: "Trưởng Thành Từ Khổ Đế",
      bagoc:
        "Nghị lực, trưởng thành qua khó khăn, nỗi đau, KHỔ ĐẾ: Triết lý, sâu sắc, đạo lý => chia sẻ, giảng dạy",
      badoc:
        "Suy tư thiếu thực tế, lý thuyết suông, cứng đầu, đắm chìm hoặc né tránh khổ đế.",
    },
    {
      number: "8",
      dketbagoc: "Uy quyền Chuyên gia về tâm",
      dketbadoc: "Kiểm soát Chuyên gia tiền",
      mota: "Tâm Linh Hay Tiền Bạc?",
      bagoc:
        "Độc lập, đáng tin cậy, ít bày tỏ tình cảm => Leader, chuyên gia => Quản lý kinh doanh, dẫn dắt tinh thần.",
      badoc: "Bất hợp tác, cô độc, lạnh nhạt, dễ bị tham tiền, thao túng.",
    },
    {
      number: "9",
      dketbagoc: "Cộng đồng Lý tưởng",
      dketbadoc: "Nhụt chí Ảo tưởng",
      mota: "Lý Tưởng Hay Ảo Tưởng? Hoài Bão Hay Tham Vọng?",
      bagoc:
        "Hoài bão, trách nhiệm, lý tưởng => hướng về con người, cộng đồng (có hiểu biết).",
      badoc: "Tham vọng, ảo tưởng, nhụt chí, chán đời.",
    },
    {
      number: "10",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 1",
      badoc: "Mắc kẹt ở số 1, không hoàn thành bài học (số 0)",
    },
    {
      number: "11",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 1 và 2",
      badoc: "Mắc kẹt ở số 1 và 2, không hoàn thành bài học (số 0).",
    },
    {
      number: "20",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 2",
      badoc: "Mắc kẹt ở số 2, không hoàn thành bài học (số 0)",
    },
    {
      number: "22",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 2 và 4",
      badoc: "Mắc kẹt ở số 2 và 4, không hoàn thành bài học (số 0)",
    },
    {
      number: "30",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 3",
      badoc: "Mắc kẹt ở số 3, không hoàn thành bài học (số 0).",
    },
    {
      number: "33",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 3 và 6",
      badoc: "Mắc kẹt ở số 3 và 6, không hoàn thành bài học (số 0)",
    },
  ],
  contentInteract: [
    {
      number: "0",
      dketbagoc: "Bậc thầy đạo lý Sẻ chia cống hiến",
      dketbadoc: "Hoang mang Thu mình lại",
      mota: "",
      bagoc: "Đạo lý, buông xả, tánh không, vô ngã",
      badoc: "Hoang mang vô định",
    },
    {
      number: "1",
      dketbagoc: "Độc lập Bản lĩnh",
      dketbadoc: "Ích kỷ Cái tôi lớn",
      mota: "Cá Tính Mạnh - Thành Và Bại",
      bagoc:
        "Tinh thần thủ lĩnh, quyết đoán, thực tế, (lãnh đạo, chuyên gia, kiên định, đôi lúc không cần ai hiểu)",
      badoc: "Tham vọng, độc tài bảo thủ, cái tôi lớn.",
    },
    {
      number: "2",
      dketbagoc: "An nhiên Vị tha",
      dketbadoc: "Thất thường\r\nLệ thuộc",
      mota: "Quan Sát Hay Phản Ứng? An Nhiên Hay Phụ Thuộc?",
      bagoc: "Trực giác, cảm xúc, nhạy cảm, tâm hồn vị tha",
      badoc:
        "Vui giận thất thường, dính mắc, lệ thuộc. Làm việc tùy hứng, không thích bị áp lực, gà công nghiệp.",
    },
    {
      number: "3",
      dketbagoc: "Học sâu Tài năng",
      dketbadoc: "Học cạn Khoe mẽ",
      mota: "Học Sâu Hay Học Cạn?",
      bagoc:
        "Tư duy, học hỏi & tìm hiểu theo chiều sâu, có thể hài hước & phán xét (tư duy mở, hài hòa, khiếu truyền cảm hứng)",
      badoc: "Tư duy hời hợt, cạn cợt, lười học hỏi",
    },
    {
      number: "4",
      dketbagoc: "Thực tế Sắp xếp",
      dketbadoc: "Thực dụng Ngoan cố",
      mota: "Kaizen Hay Thực Dụng?",
      bagoc:
        "Hành động, thực tế, óc tổ chức, sắp xếp, chỉn chu (chuyên gia). Khi đã trưởng thành: gặt hái thành quả do cố gắng những năm trước đó, 5S, KAIZEN.",
      badoc: "Thực dụng, có thể bị vật chất hóa, cứng nhắc, cầu toàn thái quá",
    },
    {
      number: "5",
      dketbagoc: "Kết nối từ tâm Tự do, lan tỏa",
      dketbadoc: "Kết nối toan tính Tự do quá đà",
      mota: "Trung Tâm Kết Nối Từ Tâm",
      bagoc:
        "Trung tâm KẾT NỐI từ TÂM: Tự do thể hiện/khiếu nghệ thuật /Chia sẻ thiện nguyện..",
      badoc:
        "Dễ bị ngoại cảnh ảnh hưởng, Thất thường, hoang dã, dễ sa đà, nhạy cảm quá sinh sợ hãi.",
    },
    {
      number: "6",
      dketbagoc: "Sáng tạo Yêu thương",
      dketbadoc: "Tối tạo Dính mắc",
      mota: "Yêu Thương Hay Dính Mắc? Sáng Tạo Hay Tối Tạo?",
      bagoc: "Sáng tạo, yêu thương (diện hẹp)",
      badoc:
        "Cẩn trọng, TÂM TƯỞNG thái quá, lan man, bất an, Stress, dính mắc.",
    },
    {
      number: "7",
      dketbagoc: "Sâu sắc, triết lý Vượt khó khổ",
      dketbadoc: "Lý thuyết suông Chìm đắm",
      mota: "Trưởng Thành Từ Khổ Đế",
      bagoc:
        "Nghị lực, trưởng thành qua khó khăn, nỗi đau, KHỔ ĐẾ: Triết lý, sâu sắc, đạo lý => chia sẻ, giảng dạy",
      badoc:
        "Suy tư thiếu thực tế, lý thuyết suông, cứng đầu, đắm chìm hoặc né tránh khổ đế.",
    },
    {
      number: "8",
      dketbagoc: "Uy quyền Chuyên gia về tâm",
      dketbadoc: "Kiểm soát Chuyên gia tiền",
      mota: "Tâm Linh Hay Tiền Bạc?",
      bagoc:
        "Độc lập, đáng tin cậy, ít bày tỏ tình cảm => Leader, chuyên gia => Quản lý kinh doanh, dẫn dắt tinh thần.",
      badoc: "Bất hợp tác, cô độc, lạnh nhạt, dễ bị tham tiền, thao túng.",
    },
    {
      number: "9",
      dketbagoc: "Cộng đồng Lý tưởng",
      dketbadoc: "Nhụt chí Ảo tưởng",
      mota: "Lý Tưởng Hay Ảo Tưởng? Hoài Bão Hay Tham Vọng?",
      bagoc:
        "Hoài bão, trách nhiệm, lý tưởng => hướng về con người, cộng đồng (có hiểu biết).",
      badoc: "Tham vọng, ảo tưởng, nhụt chí, chán đời.",
    },
    {
      number: "10",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 1",
      badoc: "Mắc kẹt ở số 1, không hoàn thành bài học (số 0)",
    },
    {
      number: "11",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 1 và 2",
      badoc: "Mắc kẹt ở số 1 và 2, không hoàn thành bài học (số 0).",
    },
    {
      number: "20",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 2",
      badoc: "Mắc kẹt ở số 2, không hoàn thành bài học (số 0)",
    },
    {
      number: "22",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 2 và 4",
      badoc: "Mắc kẹt ở số 2 và 4, không hoàn thành bài học (số 0)",
    },
    {
      number: "30",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 3",
      badoc: "Mắc kẹt ở số 3, không hoàn thành bài học (số 0).",
    },
    {
      number: "33",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 3 và 6",
      badoc: "Mắc kẹt ở số 3 và 6, không hoàn thành bài học (số 0)",
    },
  ],
  contentMission: [
    {
      number: "0",
      dketbagoc: "Bậc thầy đạo lý Sẻ chia cống hiến",
      dketbadoc: "Hoang mang Thu mình lại",
      mota: "",
      bagoc: "Đạo lý, buông xả, tánh không, vô ngã",
      badoc: "Hoang mang vô định",
    },
    {
      number: "1",
      dketbagoc: "Độc lập Bản lĩnh",
      dketbadoc: "Ích kỷ Cái tôi lớn",
      mota: "Cá Tính Mạnh - Thành Và Bại",
      bagoc:
        "Tinh thần thủ lĩnh, quyết đoán, thực tế, (lãnh đạo, chuyên gia, kiên định, đôi lúc không cần ai hiểu)",
      badoc: "Tham vọng, độc tài bảo thủ, cái tôi lớn.",
    },
    {
      number: "2",
      dketbagoc: "An nhiên Vị tha",
      dketbadoc: "Thất thường\r\nLệ thuộc",
      mota: "Quan Sát Hay Phản Ứng? An Nhiên Hay Phụ Thuộc?",
      bagoc: "Trực giác, cảm xúc, nhạy cảm, tâm hồn vị tha",
      badoc:
        "Vui giận thất thường, dính mắc, lệ thuộc. Làm việc tùy hứng, không thích bị áp lực, gà công nghiệp.",
    },
    {
      number: "3",
      dketbagoc: "Học sâu Tài năng",
      dketbadoc: "Học cạn Khoe mẽ",
      mota: "Học Sâu Hay Học Cạn?",
      bagoc:
        "Tư duy, học hỏi & tìm hiểu theo chiều sâu, có thể hài hước & phán xét (tư duy mở, hài hòa, khiếu truyền cảm hứng)",
      badoc: "Tư duy hời hợt, cạn cợt, lười học hỏi",
    },
    {
      number: "4",
      dketbagoc: "Thực tế Sắp xếp",
      dketbadoc: "Thực dụng Ngoan cố",
      mota: "Kaizen Hay Thực Dụng?",
      bagoc:
        "Hành động, thực tế, óc tổ chức, sắp xếp, chỉn chu (chuyên gia). Khi đã trưởng thành: gặt hái thành quả do cố gắng những năm trước đó, 5S, KAIZEN.",
      badoc: "Thực dụng, có thể bị vật chất hóa, cứng nhắc, cầu toàn thái quá",
    },
    {
      number: "5",
      dketbagoc: "Kết nối từ tâm Tự do, lan tỏa",
      dketbadoc: "Kết nối toan tính Tự do quá đà",
      mota: "Trung Tâm Kết Nối Từ Tâm",
      bagoc:
        "Trung tâm KẾT NỐI từ TÂM: Tự do thể hiện/khiếu nghệ thuật /Chia sẻ thiện nguyện..",
      badoc:
        "Dễ bị ngoại cảnh ảnh hưởng, Thất thường, hoang dã, dễ sa đà, nhạy cảm quá sinh sợ hãi.",
    },
    {
      number: "6",
      dketbagoc: "Sáng tạo Yêu thương",
      dketbadoc: "Tối tạo Dính mắc",
      mota: "Yêu Thương Hay Dính Mắc? Sáng Tạo Hay Tối Tạo?",
      bagoc: "Sáng tạo, yêu thương (diện hẹp)",
      badoc:
        "Cẩn trọng, TÂM TƯỞNG thái quá, lan man, bất an, Stress, dính mắc.",
    },
    {
      number: "7",
      dketbagoc: "Sâu sắc, triết lý Vượt khó khổ",
      dketbadoc: "Lý thuyết suông Chìm đắm",
      mota: "Trưởng Thành Từ Khổ Đế",
      bagoc:
        "Nghị lực, trưởng thành qua khó khăn, nỗi đau, KHỔ ĐẾ: Triết lý, sâu sắc, đạo lý => chia sẻ, giảng dạy",
      badoc:
        "Suy tư thiếu thực tế, lý thuyết suông, cứng đầu, đắm chìm hoặc né tránh khổ đế.",
    },
    {
      number: "8",
      dketbagoc: "Uy quyền Chuyên gia về tâm",
      dketbadoc: "Kiểm soát Chuyên gia tiền",
      mota: "Tâm Linh Hay Tiền Bạc?",
      bagoc:
        "Độc lập, đáng tin cậy, ít bày tỏ tình cảm => Leader, chuyên gia => Quản lý kinh doanh, dẫn dắt tinh thần.",
      badoc: "Bất hợp tác, cô độc, lạnh nhạt, dễ bị tham tiền, thao túng.",
    },
    {
      number: "9",
      dketbagoc: "Cộng đồng Lý tưởng",
      dketbadoc: "Nhụt chí Ảo tưởng",
      mota: "Lý Tưởng Hay Ảo Tưởng? Hoài Bão Hay Tham Vọng?",
      bagoc:
        "Hoài bão, trách nhiệm, lý tưởng => hướng về con người, cộng đồng (có hiểu biết).",
      badoc: "Tham vọng, ảo tưởng, nhụt chí, chán đời.",
    },
    {
      number: "10",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 1",
      badoc: "Mắc kẹt ở số 1, không hoàn thành bài học (số 0)",
    },
    {
      number: "11",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 1 và 2",
      badoc: "Mắc kẹt ở số 1 và 2, không hoàn thành bài học (số 0).",
    },
    {
      number: "20",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 2",
      badoc: "Mắc kẹt ở số 2, không hoàn thành bài học (số 0)",
    },
    {
      number: "22",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 2 và 4",
      badoc: "Mắc kẹt ở số 2 và 4, không hoàn thành bài học (số 0)",
    },
    {
      number: "30",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 3",
      badoc: "Mắc kẹt ở số 3, không hoàn thành bài học (số 0).",
    },
    {
      number: "33",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 3 và 6",
      badoc: "Mắc kẹt ở số 3 và 6, không hoàn thành bài học (số 0)",
    },
  ],
  contentMature: [
    {
      number: "0",
      dketbagoc: "Bậc thầy đạo lý Sẻ chia cống hiến",
      dketbadoc: "Hoang mang Thu mình lại",
      mota: "",
      bagoc: "Đạo lý, buông xả, tánh không, vô ngã",
      badoc: "Hoang mang vô định",
    },
    {
      number: "1",
      dketbagoc: "Độc lập Bản lĩnh",
      dketbadoc: "Ích kỷ Cái tôi lớn",
      mota: "Cá Tính Mạnh - Thành Và Bại",
      bagoc:
        "Tinh thần thủ lĩnh, quyết đoán, thực tế, (lãnh đạo, chuyên gia, kiên định, đôi lúc không cần ai hiểu)",
      badoc: "Tham vọng, độc tài bảo thủ, cái tôi lớn.",
    },
    {
      number: "2",
      dketbagoc: "An nhiên Vị tha",
      dketbadoc: "Thất thường\r\nLệ thuộc",
      mota: "Quan Sát Hay Phản Ứng? An Nhiên Hay Phụ Thuộc?",
      bagoc: "Trực giác, cảm xúc, nhạy cảm, tâm hồn vị tha",
      badoc:
        "Vui giận thất thường, dính mắc, lệ thuộc. Làm việc tùy hứng, không thích bị áp lực, gà công nghiệp.",
    },
    {
      number: "3",
      dketbagoc: "Học sâu Tài năng",
      dketbadoc: "Học cạn Khoe mẽ",
      mota: "Học Sâu Hay Học Cạn?",
      bagoc:
        "Tư duy, học hỏi & tìm hiểu theo chiều sâu, có thể hài hước & phán xét (tư duy mở, hài hòa, khiếu truyền cảm hứng)",
      badoc: "Tư duy hời hợt, cạn cợt, lười học hỏi",
    },
    {
      number: "4",
      dketbagoc: "Thực tế Sắp xếp",
      dketbadoc: "Thực dụng Ngoan cố",
      mota: "Kaizen Hay Thực Dụng?",
      bagoc:
        "Hành động, thực tế, óc tổ chức, sắp xếp, chỉn chu (chuyên gia). Khi đã trưởng thành: gặt hái thành quả do cố gắng những năm trước đó, 5S, KAIZEN.",
      badoc: "Thực dụng, có thể bị vật chất hóa, cứng nhắc, cầu toàn thái quá",
    },
    {
      number: "5",
      dketbagoc: "Kết nối từ tâm Tự do, lan tỏa",
      dketbadoc: "Kết nối toan tính Tự do quá đà",
      mota: "Trung Tâm Kết Nối Từ Tâm",
      bagoc:
        "Trung tâm KẾT NỐI từ TÂM: Tự do thể hiện/khiếu nghệ thuật /Chia sẻ thiện nguyện..",
      badoc:
        "Dễ bị ngoại cảnh ảnh hưởng, Thất thường, hoang dã, dễ sa đà, nhạy cảm quá sinh sợ hãi.",
    },
    {
      number: "6",
      dketbagoc: "Sáng tạo Yêu thương",
      dketbadoc: "Tối tạo Dính mắc",
      mota: "Yêu Thương Hay Dính Mắc? Sáng Tạo Hay Tối Tạo?",
      bagoc: "Sáng tạo, yêu thương (diện hẹp)",
      badoc:
        "Cẩn trọng, TÂM TƯỞNG thái quá, lan man, bất an, Stress, dính mắc.",
    },
    {
      number: "7",
      dketbagoc: "Sâu sắc, triết lý Vượt khó khổ",
      dketbadoc: "Lý thuyết suông Chìm đắm",
      mota: "Trưởng Thành Từ Khổ Đế",
      bagoc:
        "Nghị lực, trưởng thành qua khó khăn, nỗi đau, KHỔ ĐẾ: Triết lý, sâu sắc, đạo lý => chia sẻ, giảng dạy",
      badoc:
        "Suy tư thiếu thực tế, lý thuyết suông, cứng đầu, đắm chìm hoặc né tránh khổ đế.",
    },
    {
      number: "8",
      dketbagoc: "Uy quyền Chuyên gia về tâm",
      dketbadoc: "Kiểm soát Chuyên gia tiền",
      mota: "Tâm Linh Hay Tiền Bạc?",
      bagoc:
        "Độc lập, đáng tin cậy, ít bày tỏ tình cảm => Leader, chuyên gia => Quản lý kinh doanh, dẫn dắt tinh thần.",
      badoc: "Bất hợp tác, cô độc, lạnh nhạt, dễ bị tham tiền, thao túng.",
    },
    {
      number: "9",
      dketbagoc: "Cộng đồng Lý tưởng",
      dketbadoc: "Nhụt chí Ảo tưởng",
      mota: "Lý Tưởng Hay Ảo Tưởng? Hoài Bão Hay Tham Vọng?",
      bagoc:
        "Hoài bão, trách nhiệm, lý tưởng => hướng về con người, cộng đồng (có hiểu biết).",
      badoc: "Tham vọng, ảo tưởng, nhụt chí, chán đời.",
    },
    {
      number: "10",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 1",
      badoc: "Mắc kẹt ở số 1, không hoàn thành bài học (số 0)",
    },
    {
      number: "11",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 1 và 2",
      badoc: "Mắc kẹt ở số 1 và 2, không hoàn thành bài học (số 0).",
    },
    {
      number: "20",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 2",
      badoc: "Mắc kẹt ở số 2, không hoàn thành bài học (số 0)",
    },
    {
      number: "22",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 2 và 4",
      badoc: "Mắc kẹt ở số 2 và 4, không hoàn thành bài học (số 0)",
    },
    {
      number: "30",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 3",
      badoc: "Mắc kẹt ở số 3, không hoàn thành bài học (số 0).",
    },
    {
      number: "33",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 3 và 6",
      badoc: "Mắc kẹt ở số 3 và 6, không hoàn thành bài học (số 0)",
    },
  ],
  contentLife: [
    {
      number: "0",
      dketbagoc: "Bậc thầy đạo lý Sẻ chia cống hiến",
      dketbadoc: "Hoang mang Thu mình lại",
      mota: "",
      bagoc: "Đạo lý, buông xả, tánh không, vô ngã",
      badoc: "Hoang mang vô định",
    },
    {
      number: "1",
      dketbagoc: "Độc lập Bản lĩnh",
      dketbadoc: "Ích kỷ Cái tôi lớn",
      mota: "Cá Tính Mạnh - Thành Và Bại",
      bagoc:
        "Tinh thần thủ lĩnh, quyết đoán, thực tế, (lãnh đạo, chuyên gia, kiên định, đôi lúc không cần ai hiểu)",
      badoc: "Tham vọng, độc tài bảo thủ, cái tôi lớn.",
    },
    {
      number: "2",
      dketbagoc: "An nhiên Vị tha",
      dketbadoc: "Thất thường\r\nLệ thuộc",
      mota: "Quan Sát Hay Phản Ứng? An Nhiên Hay Phụ Thuộc?",
      bagoc: "Trực giác, cảm xúc, nhạy cảm, tâm hồn vị tha",
      badoc:
        "Vui giận thất thường, dính mắc, lệ thuộc. Làm việc tùy hứng, không thích bị áp lực, gà công nghiệp.",
    },
    {
      number: "3",
      dketbagoc: "Học sâu Tài năng",
      dketbadoc: "Học cạn Khoe mẽ",
      mota: "Học Sâu Hay Học Cạn?",
      bagoc:
        "Tư duy, học hỏi & tìm hiểu theo chiều sâu, có thể hài hước & phán xét (tư duy mở, hài hòa, khiếu truyền cảm hứng)",
      badoc: "Tư duy hời hợt, cạn cợt, lười học hỏi",
    },
    {
      number: "4",
      dketbagoc: "Thực tế Sắp xếp",
      dketbadoc: "Thực dụng Ngoan cố",
      mota: "Kaizen Hay Thực Dụng?",
      bagoc:
        "Hành động, thực tế, óc tổ chức, sắp xếp, chỉn chu (chuyên gia). Khi đã trưởng thành: gặt hái thành quả do cố gắng những năm trước đó, 5S, KAIZEN.",
      badoc: "Thực dụng, có thể bị vật chất hóa, cứng nhắc, cầu toàn thái quá",
    },
    {
      number: "5",
      dketbagoc: "Kết nối từ tâm Tự do, lan tỏa",
      dketbadoc: "Kết nối toan tính Tự do quá đà",
      mota: "Trung Tâm Kết Nối Từ Tâm",
      bagoc:
        "Trung tâm KẾT NỐI từ TÂM: Tự do thể hiện/khiếu nghệ thuật /Chia sẻ thiện nguyện..",
      badoc:
        "Dễ bị ngoại cảnh ảnh hưởng, Thất thường, hoang dã, dễ sa đà, nhạy cảm quá sinh sợ hãi.",
    },
    {
      number: "6",
      dketbagoc: "Sáng tạo Yêu thương",
      dketbadoc: "Tối tạo Dính mắc",
      mota: "Yêu Thương Hay Dính Mắc? Sáng Tạo Hay Tối Tạo?",
      bagoc: "Sáng tạo, yêu thương (diện hẹp)",
      badoc:
        "Cẩn trọng, TÂM TƯỞNG thái quá, lan man, bất an, Stress, dính mắc.",
    },
    {
      number: "7",
      dketbagoc: "Sâu sắc, triết lý Vượt khó khổ",
      dketbadoc: "Lý thuyết suông Chìm đắm",
      mota: "Trưởng Thành Từ Khổ Đế",
      bagoc:
        "Nghị lực, trưởng thành qua khó khăn, nỗi đau, KHỔ ĐẾ: Triết lý, sâu sắc, đạo lý => chia sẻ, giảng dạy",
      badoc:
        "Suy tư thiếu thực tế, lý thuyết suông, cứng đầu, đắm chìm hoặc né tránh khổ đế.",
    },
    {
      number: "8",
      dketbagoc: "Uy quyền Chuyên gia về tâm",
      dketbadoc: "Kiểm soát Chuyên gia tiền",
      mota: "Tâm Linh Hay Tiền Bạc?",
      bagoc:
        "Độc lập, đáng tin cậy, ít bày tỏ tình cảm => Leader, chuyên gia => Quản lý kinh doanh, dẫn dắt tinh thần.",
      badoc: "Bất hợp tác, cô độc, lạnh nhạt, dễ bị tham tiền, thao túng.",
    },
    {
      number: "9",
      dketbagoc: "Cộng đồng Lý tưởng",
      dketbadoc: "Nhụt chí Ảo tưởng",
      mota: "Lý Tưởng Hay Ảo Tưởng? Hoài Bão Hay Tham Vọng?",
      bagoc:
        "Hoài bão, trách nhiệm, lý tưởng => hướng về con người, cộng đồng (có hiểu biết).",
      badoc: "Tham vọng, ảo tưởng, nhụt chí, chán đời.",
    },
    {
      number: "10",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 1",
      badoc: "Mắc kẹt ở số 1, không hoàn thành bài học (số 0)",
    },
    {
      number: "11",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 1 và 2",
      badoc: "Mắc kẹt ở số 1 và 2, không hoàn thành bài học (số 0).",
    },
    {
      number: "20",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 2",
      badoc: "Mắc kẹt ở số 2, không hoàn thành bài học (số 0)",
    },
    {
      number: "22",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 2 và 4",
      badoc: "Mắc kẹt ở số 2 và 4, không hoàn thành bài học (số 0)",
    },
    {
      number: "30",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 3",
      badoc: "Mắc kẹt ở số 3, không hoàn thành bài học (số 0).",
    },
    {
      number: "33",
      dketbagoc: "",
      dketbadoc: "",
      mota: "",
      bagoc: "Trưởng thành từ số 3 và 6",
      badoc: "Mắc kẹt ở số 3 và 6, không hoàn thành bài học (số 0)",
    },
  ],
  classRoomLinks: [],
  contentTopPage: {
    imgUrl: "/images/introduction.jpg",
    title: "Kết nối với vũ trụ thông qua ngôn ngữ của con số!",
    content:
      "Thần số học là một ngành nghiên cứu có lịch sử lâu đời và đã tồn tại trong nhiều nền văn minh khác nhau trên khắp thế giới. Nó được xem như một hệ thống phiên bản của toán học tâm linh, nghiên cứu sự tương quan giữa con số và sự kiện trong cuộc sống con người.\nTheo thần số học, mỗi con số mang một ý nghĩa đặc biệt và có thể tiết lộ thông tin về cá nhân, tương lai và môi trường xung quanh chúng ta. Từ việc tính toán ngày tháng năm sinh cho đến việc phân tích con số xuất hiện trong cuộc sống hàng ngày, thần số học cung cấp một cách nhìn sâu hơn vào thế giới xung quanh chúng ta. Một trong những khái niệm quan trọng trong thần số học là 'số mệnh'.\nSố mệnh là con số chủ đạo được xác định từ ngày sinh của một người. Nó được cho là có ảnh hưởng lớn đến tính cách, sự nghiệp và các sự kiện trong cuộc sống của một người. Bằng cách phân tích số mệnh của một người, thần số học có thể cung cấp thông tin về những mặt tích cực và tiêu cực của cuộc sống của họ, từ đó giúp họ hiểu và khai phá tiềm năng của bản thân. Ngoài số mệnh, thần số học còn quan tâm đến những con số khác như 'đường số' và 'số tài vận'.",
  },
  isUse: true,
};
