import { ROUTES } from "app/configs/routers";
import Home from "app/containers/Home";

const routes = [
  {
    path: ROUTES.HOME,
    element: Home,
    meta: {
      middleware: [],
    },
  },
];

export { routes };
